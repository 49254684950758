.CategoryForm__form {
  width: 300px;
  margin: 10px auto;
}

.CategoryForm__label {
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.CategoryForm__input_and_button {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.CategoryForm__input {
  flex: 1 1 auto;
  padding: 4px;
}

.CategoryForm__submit_button {
  flex: 0 0 auto;
  padding: 4px;
}

.CategoryForm__label_text {
  flex: 0 0 auto;
  font-size: 0.7em;
  margin-bottom: 1px;
}
