.ImageUploader__root {
  background-color: #f0f0f0;
  border-radius: 10px;
  border: 1px solid #474747;
  min-height: 300px;
  width: 300px;
}

.ImageUploader__thumbnails {
  display: flex;
  flex-wrap: wrap;
}

.ImageUploader__thumbnail {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  margin: 10px;
}

.ImageUploader__error {
  position: absolute;
  top: 50%;
  left: 5px;
  right: 5px;
  transform: translateY(-50%);
  margin-top: 3px;
  text-overflow: ellipsis;
  color: #a10707;
  font-size: 10px;
}

.ImageUploader__caption {
  position: absolute;
  left: 0;
  width: calc(100% - 10px);
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
