.preloaded-image__container {
  position: relative;
  overflow: hidden;
}

.preloaded-image__cover {
  background-color: #e0e0e0;
  transition: opacity 300ms ease-in-out;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.preloaded-image {
  position: absolute;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center center;
  opacity: 1;
  top: -1px;
  right: 0;
  bottom: 0;
  left: 0;
}

.preloaded-image__img {
  opacity: 1;
  background-color: transparent;
  background-repeat: no-repeat;
}

.preloaded-image__preloading .preloaded-image:not(.preloaded-image__video),
.preloaded-image__preloading .preloaded-image__img {
  opacity: 0;
}

.preloaded-image__preloading .preloaded-image__cover {
  opacity: 1;
}

.preloaded-image__video {
  margin: auto;
  position: absolute;
  z-index: 1;
  left: 0;
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@supports (-ms-ime-align: auto) {
  .preloaded-image__video {
    object-fit: none;
    margin: none;
    position: inherit;
    z-index: 1;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    height: auto;
    width: 100%;
  }
}
