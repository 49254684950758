.Profile__hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.Profile__photo {
  border-radius: 50%;
}
