.EditProfile__hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.EditProfile__photo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  margin: 10px;
}

.EditProfile__form {
  width: 300px;
  margin: 10px auto;
}

.EditProfile__save_button {
  margin: 20px;
}

.EditProfile__upload_thumbnail {
  border-radius: 50%;
}
