.Edit__hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.Edit__label {
  display: block;
  margin: 10px;
}
