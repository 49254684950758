.Button {
  text-transform: none;
  -webkit-appearance: button;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.Button:disabled {
  color: gray;
}
