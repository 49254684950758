body {
  overflow-x: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

html {
  height: 100%;
}

main {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px; /* Nav Height */
  padding-bottom: 10px;
}

input,
textarea,
select,
option {
  font-size: inherit;
}
