.Signup__hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.Signup__photo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  margin: 10px;
}

.Signup__form {
  width: 300px;
  margin: 10px auto;
}

.Signup__create_button {
  margin: 20px;
}

.Signup__upload_thumbnail {
  border-radius: 50%;
}
