.ImageUploadThumbnail {
  position: relative;
}

.ImageUploadThumbnail__is_preview {
  opacity: 0.5;
}

.ImageUploadThumbnail__is_dragging {
  opacity: 0.5;
}

.ImageUploadThumbnail__button {
  position: absolute;
  left: 1px;
  top: 1px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}

.ImageUploadThumbnail__order {
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}

.ImageUploadThumbnail__progress_bar {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  margin: 3px auto;
  height: 5px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 2px;
}

.ImageUploadThumbnail__progress {
  height: 5px;
  background-color: #474747;
}
