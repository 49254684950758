.Main__hero {
  display: flex;
  margin: 10px 0;
}

.category-list {
  list-style-type: none;
  white-space: nowrap;
  font-family: 'Trebuchet MS';
  font-size: 40px;
  user-select: none;
  padding-top: 14px;
}
