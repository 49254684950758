.CommentForm__label {
  display: flex;
}

.CommentForm__container {
  display: flex;
  align-items: flex-start;
}

.CommentForm__content {
  align-items: flex-start;
  border-radius: 5px;
  background-color: #eeeeee;
  margin-bottom: 5px;
  flex: 1 1 auto;
  padding: 4px;
}

.CommentForm__header {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.CommentForm__username_and_date {
  display: flex;
  align-items: baseline;
  font-size: 10px;
  text-align: left;
}

.CommentForm__username {
  font-weight: bold;
  margin-right: 5px;
}

.CommentForm__createdAt {
  font-size: 10px;
  color: rgb(66, 66, 66);
}

.CommentForm__cancel_link,
.CommentForm__submit_button {
  font-size: 10px;
  margin-left: 5px;
}

.CommentForm__text {
  margin-top: 4px;
  font-size: 12px;
  text-align: left;
  line-height: normal;
}

.CommentForm__input {
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 0;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
}
