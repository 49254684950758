.Project__hero {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.Project__execution {
  margin: 10px auto;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 10px;
  max-width: 300px;
}

.Project__title {
  margin-bottom: 10px;
}

.Project__create_button {
  margin: 20px;
}

.Project__edit_link {
  display: inline-block;
  margin-left: 3px;
}

.Project__link,
.Project__link:hover {
  text-decoration: none;
}
